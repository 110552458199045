import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  Alert,
  InputAdornment,
  Select, MenuItem, InputLabel, FormControl
} from "@mui/material"; // Added InputAdornment
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
  VisibilityOff,
  Add as AddIcon,
  Search as SearchIcon,
} from "@mui/icons-material"; // Added SearchIcon
import axios from "axios";

const GerirUtilizadores = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState({});

  // --- State for search filters ---
  const [searchUsername, setSearchUsername] = useState("");
  const [searchEntity, setSearchEntity] = useState("");
  const [searchRole, setSearchRole] = useState("");

  // --- Fetch users from the API ---
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          "https://preventech-proxy-service.onrender.com/api/user/getAllUsers"
        );
        setUsers(response.data || []);
        console.log("Successfully fetched users:", response.data);
      } catch (err) {
        console.error("Error fetching users:", err);
        setError(
          err.response?.data?.error || err.message || "Failed to fetch users"
        );
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // --- Memoized filtering logic ---
  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const usernameMatch =
        !searchUsername ||
        (user.username &&
          user.username.toLowerCase().includes(searchUsername.toLowerCase()));
      const entityMatch =
        !searchEntity ||
        (user.entity &&
          user.entity.toLowerCase().includes(searchEntity.toLowerCase()));
      const roleMatch =
        !searchRole ||
        (user.role &&
          user.role.toLowerCase().includes(searchRole.toLowerCase()));
      return usernameMatch && entityMatch && roleMatch;
    });
  }, [users, searchUsername, searchEntity, searchRole]);

  // Handle open/close dialog
  const handleDialogOpen = (user = null) => {
    const initialUserData = {
      username: "",
      password: "",
      entity: "",
      role: "",
    };
    setSelectedUser(user ? { ...user } : initialUserData);
    setIsEditMode(!!user);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  // --- API Interaction Logic (Implement actual calls) ---
  const handleSaveUser = async () => {
    if (
      !selectedUser?.username ||
      (!isEditMode && !selectedUser?.password) ||
      !selectedUser?.entity ||
      !selectedUser?.role
    ) {
      alert(
        "Please fill in all required fields (Password required for new users)."
      );
      return;
    }
    setLoading(true);
    setError(null);
    try {
      if (isEditMode) {

        try {
          console.log("Updating user (API call):", selectedUser);

          // Prepare the data to be sent to the backend
          const updateData = { ...selectedUser };
          if (!updateData.password) delete updateData.password; // Only send password if present

          // Send PUT request to update user
          const response = await axios.put(
            `https://preventech-proxy-service.onrender.com/api/user/updateUser/${selectedUser.id}`,
            updateData
          );

          // Update the users state optimistically (after API response is received)
          setUsers(
            users.map((u) => (u.id === selectedUser.id ? response.data : u))
          );
        } catch (error) {
          console.error("Error updating user:", error);
          alert(`Failed to update user: ${error.response?.data?.error || error.message}`);
        }
      } else {
        console.log("Creating user (API call):", selectedUser);

        try {
          const response = await axios.post(
            "https://preventech-proxy-service.onrender.com/api/user/insertNewUser",
            selectedUser
          );

          // Use the response data (includes the proper user ID)
          setUsers([...users, response.data]);
        } catch (error) {
          console.error("Error creating user:", error);
          alert(`Failed to create user: ${error.response?.data?.error || error.message}`);
        }
      }
      handleDialogClose();
    } catch (err) {
      console.error("Error saving user:", err);
      const errorMsg =
        err.response?.data?.error || err.message || "Failed to save user";
      setError(errorMsg);
      alert(`Error: ${errorMsg}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (id) => {
    if (!window.confirm(`Are you sure you want to delete user with ID: ${id}?`))
      return;

    setLoading(true);
    setError(null);

    try {
      console.log("Deleting user (API call):", id);

      // Use the id passed to the function (instead of selectedUser.id)
      await axios.delete(`https://preventech-proxy-service.onrender.com/api/user/deleteUser/${id}`);

      // Optimistic update to remove the user from the UI immediately
      setUsers(users.filter((user) => user.id !== id));
    } catch (err) {
      console.error("Error deleting user:", err);
      const errorMsg = err.response?.data?.error || err.message || "Failed to delete user";
      setError(errorMsg);
      alert(`Error: ${errorMsg}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTogglePasswordVisibility = (id) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getRowStyle = (index) => ({
    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ececec",
    "&:hover": {
      backgroundColor: "#dcdcdc",
    },
  });

  // --- Loading State ---
  if (loading && users.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>A carregar utilizadores...</Typography>
      </Box>
    );
  }

  // --- Error State ---
  if (error && users.length === 0) {
    return (
      <Box sx={{ padding: 3 }}>
        <Alert severity="error">
          <Typography>Erro a carregar utilizadores: {error}</Typography>
        </Alert>
        <Button onClick={() => window.location.reload()} sx={{ mt: 2 }}>
          Tentar Novamente
        </Button>
      </Box>
    );
  }

  // --- Main Render ---
  return (
    <Box sx={{ padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#808080",
            mb: 0 /* Removed bottom margin here */,
          }}
        >
          Gerir Utilizadores
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen()}
          sx={{ "&:hover": { backgroundColor: "#1976d2" } }}
          startIcon={<AddIcon />}
          disabled={loading}
        >
          Adicionar Novo
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* --- Filter Bar --- */}
      <Box
        sx={{
          display: "flex",
          gap: 2, // Adds space between items
          mb: 3, // Margin below the filter bar
          flexWrap: "wrap", // Allow filters to wrap on smaller screens
        }}
      >
        <TextField
          label="Filtrar Username"
          variant="outlined"
          size="small"
          value={searchUsername}
          onChange={(e) => setSearchUsername(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, minWidth: "180px" }} // Allow textfield to grow
        />
        <TextField
          label="Filtrar Entidade"
          variant="outlined"
          size="small"
          value={searchEntity}
          onChange={(e) => setSearchEntity(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, minWidth: "180px" }}
        />
        <TextField
          label="Filtrar Role"
          variant="outlined"
          size="small"
          value={searchRole}
          onChange={(e) => setSearchRole(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, minWidth: "180px" }}
        />
      </Box>
      {/* --- End Filter Bar --- */}

      {/* Show API errors that occur *after* initial load */}
      {error && users.length > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Erro: {error}
        </Alert>
      )}
      {/* Show loading indicator during save/delete operations */}
      {loading && users.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <CircularProgress size={24} sx={{ mr: 1 }} />
          <Typography>A processar...</Typography>
        </Box>
      )}

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)" }}
      >
        <Table stickyHeader>
          {/* Single Header Row */}
          <TableHead sx={{ backgroundColor: "#ececec" }}>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: "black", minWidth: 150 }}
                align="left"
              >
                Username
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "black", minWidth: 170 }}
                align="left"
              >
                Password
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "black", minWidth: 150 }}
                align="left"
              >
                Entidade
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "black", minWidth: 120 }}
                align="left"
              >
                Role
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "black", minWidth: 120 }}
                align="center"
              >
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Use filteredUsers for rendering */}
            {filteredUsers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography>
                    {users.length === 0
                      ? "Nenhum utilizador encontrado."
                      : "Nenhum utilizador corresponde aos filtros aplicados."}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredUsers.map((user, index) => (
                <TableRow key={user.id || index} sx={getRowStyle(index)}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {passwordVisible[user.id] ? user.password : "********"}
                    </span>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleTogglePasswordVisibility(user.id)}
                      sx={{ marginLeft: 1 }}
                    >
                      {passwordVisible[user.id] ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>{user.entity}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleDialogOpen(user)}
                      sx={{
                        marginRight: 1,
                        "&:hover": { backgroundColor: "#c5cae9" },
                      }}
                      disabled={loading}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteUser(user.id)}
                      sx={{ "&:hover": { backgroundColor: "#ffcccb" } }}
                      disabled={loading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog remains the same */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ backgroundColor: "#e3f2fd", color: "#1976d2" }}>
          {isEditMode ? "Editar Utilizador" : "Adicionar Novo Utilizador"}
        </DialogTitle>
        <DialogContent sx={{ pt: "20px !important" }}>
          <TextField
            label="Username"
            name="username"
            value={selectedUser?.username || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Password"
            name="password"
            value={selectedUser?.password || ""}
            type={passwordVisible ? "text" : "password"} // Toggle visibility
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required={!isEditMode} // Only require password if not in edit mode
            helperText={
              isEditMode ? "Deixe em branco para manter a password atual" : ""
            }
          />
          <IconButton
            color="primary"
            size="small"
            onClick={() => setPasswordVisible((prevState) => !prevState)} // Toggle visibility
            sx={{ position: 'absolute', right: 16, top: 'calc(50% - 12px)', zIndex: 1 }}
          >
            {passwordVisible ? (
              <VisibilityOff fontSize="small" />
            ) : (
              <Visibility fontSize="small" />
            )}
          </IconButton>
          <TextField
            label="Entidade"
            name="entity"
            value={selectedUser?.entity || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              name="role"
              value={selectedUser?.role || ""}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="dashboard">Dashboard</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            disabled={loading}
          >
            {" "}
            Cancelar{" "}
          </Button>
          <Button
            onClick={handleSaveUser}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {" "}
            {isEditMode ? "Salvar Alterações" : "Criar Utilizador"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GerirUtilizadores;
