import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { LocalHospitalRounded, FireTruck, CarCrash, Engineering } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";

const iconMapping = {
    emergenciaph: <LocalHospitalRounded fontSize="small" />, // Emergency Icon
    incendio: <FireTruck fontSize="small" />, // Fire Icon
    acidente: <CarCrash fontSize="small" />, // Accident Icon
    outros: <Engineering fontSize="small" />
};

const headerColorMapping = {
    emergenciaph: "#1976d2", // Blue
    incendio: "#d32f2f", // Red
    acidente: "#ed6c02", //orange
    outros: "#A0A0A0" // gray
};

const DashboardCard = ({ title, value, percentageChange, valuePrefix = "", category, yesterdayValue, totalYearValue, averagePerDay }) => {
    const isIncrease = percentageChange >= 0;
    const absPercentageChange = Math.abs(percentageChange).toFixed(1);
    const ComparisonIcon = isIncrease ? ArrowUpwardIcon : ArrowDownwardIcon;
    const comparisonColor = isIncrease ? green[600] : red[600];
    const formattedValue = typeof value === "number" ? value.toLocaleString() : value;
    const formattedYesterday = typeof yesterdayValue === "number" ? yesterdayValue.toLocaleString() : yesterdayValue;
    const formattedTotalYear = typeof totalYearValue === "number" ? totalYearValue.toLocaleString() : totalYearValue;
    const formattedAveragePerDay = typeof averagePerDay === "number" ? averagePerDay.toLocaleString() : averagePerDay;
    const headerColor = headerColorMapping[category] || "#2e7d32";

    let ontemPercentageChange = 0;
    if (typeof value === "number" && typeof yesterdayValue === "number" && yesterdayValue !== 0) {
        ontemPercentageChange = ((value - yesterdayValue) / yesterdayValue) * 100;
    } else if (typeof yesterdayValue === "number" && yesterdayValue === 0 && typeof value === "number" && value > 0) {
        ontemPercentageChange = 100;
    }

    const absOntemPercentageChange = Math.abs(ontemPercentageChange).toFixed(1);
    const ontemIsIncrease = ontemPercentageChange >= 0;
    const ontemComparisonColor = ontemIsIncrease ? green[600] : red[600];
    const OntemComparisonIcon = ontemIsIncrease ? ArrowUpwardIcon : ArrowDownwardIcon;

    return (
        <Card>
            <Card sx={{ position: "relative", minWidth: 200, height: "100%", display: "flex", flexDirection: "column", borderRadius: 2, boxShadow: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: headerColor, padding: 1.5 }}>
                    <IconButton sx={{ backgroundColor: "white", boxShadow: 1, borderRadius: "8px" }}>
                        {iconMapping[category] || <LocalHospitalRounded fontSize="small" />}
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: "bold", color: "white", flexGrow: 1, textAlign: "center" }}>
                        {title}
                    </Typography>
                </Box>
                <CardContent sx={{ flexGrow: 1, textAlign: "center", marginBottom: "50px" }}>
                    <Typography variant="h3" sx={{ fontWeight: "bold", my: 1 }}>
                        {valuePrefix}
                        {formattedValue}
                    </Typography>
                </CardContent>

                <Box sx={{
                    position: "absolute",
                    bottom: 8,
                    left: 8,
                    backgroundColor: "rgba(0,0,0,0.05)",
                    borderRadius: 1,
                    paddingX: 1,
                    paddingY: 0.5
                }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: ontemComparisonColor }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold", color: "gray" }}>
                            Ontem: {formattedYesterday || 0}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <OntemComparisonIcon sx={{ fontSize: "1rem", mr: 0.5, color: ontemComparisonColor }} />
                            <Typography variant="body2" sx={{ fontWeight: "bold", color: ontemComparisonColor }}>
                                ({absOntemPercentageChange}%)
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    position: "absolute",
                    bottom: 8,
                    right: 8,
                    backgroundColor: "rgba(0,0,0,0.05)",
                    borderRadius: 1,
                    paddingX: 1,
                    paddingY: 0.5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end'
                }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", color: "gray" }}>
                        Ano: {formattedTotalYear || 0}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", color: "gray" }}>
                        {formattedAveragePerDay + " / dia" || 0}
                    </Typography>
                </Box>

            </Card>
        </Card>
    );
};

DashboardCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    yesterdayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    percentageChange: PropTypes.number.isRequired,
    valuePrefix: PropTypes.string,
    category: PropTypes.string.isRequired,
};

export default DashboardCard;
