import pdfFile from "../assets/Verbete-INEM.pdf";
import Utils from "./utils";

class SaveToDevice {

    //SAVE TO DEVICE
    static saveToDevice = async (
        pdfBlob,
        fileName,
        formData,
        num_ocorrencia,
        setIsUploading,
        setUploadProgress,
        item) => {

        if (
            formData.hora_vitima !== '' && formData.hora_vitima !== null &&
            formData.hora_caminho_hospital !== '' && formData.hora_caminho_hospital !== null &&
            formData.hora_chegada_unidade_hospitalar !== '' && formData.hora_chegada_unidade_hospitalar !== null
        ) {
            try {
                console.log("formData before submission:", formData); // Debugging
                if (!pdfFile) {
                    throw new Error("PDF template path is missing.");
                }

                // Convert formData values to uppercase
                const upperCaseFormData = Object.fromEntries(
                    Object.entries(formData).map(([key, value]) => [
                        key,
                        typeof value === "string" ? value.toUpperCase() : value,
                    ])
                );

                const templateUrl = pdfFile; // Path to your PDF template

                if (!upperCaseFormData || Object.keys(upperCaseFormData).length === 0) {
                    throw new Error("formData is empty or invalid after uppercasing.");
                }

                const generatedPdf = await Utils.fillPdfTemplate(templateUrl, upperCaseFormData);

                if (!generatedPdf) {
                    throw new Error("PDF generation failed.");
                }

                // Create a Blob from the generated PDF
                const pdfBlob = new Blob([generatedPdf], { type: "application/pdf" });

                // Create a download link
                const downloadUrl = URL.createObjectURL(pdfBlob);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = fileName; // Name for the downloaded file
                document.body.appendChild(link); // Append the link to the body
                link.click(); // Trigger the download
                document.body.removeChild(link); // Clean up the DOM

                console.log("PDF saved successfully.");
                alert("Verbete INEM Guardado Com sucesso.");

            } catch (error) {
                console.error("Error during PDF generation or save:", error);
                alert("An error occurred while saving the PDF. Please try again.");
            }
        } else {
            alert("Dados não preenchidos (Hora Chegada à Vitima, Caminho U. Saúde, Chegada U. Saúde ). Por favor, preencha antes de finalizar.");
        }
    };


}

export default SaveToDevice;
