import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import DashboardCardMui from "./DashboardCard";

const fetchDashboardData = async (retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch("https://preventech-proxy-service.onrender.com/api/statistics/getTodayRequests");
            if (!response.ok) throw new Error("Failed to fetch data");
            const data = await response.json();
            return data.summary || { emergenciaph: 0, incendio: 0, acidente: 0, outros: 0 };
        } catch (error) {
            console.error(`Attempt ${i + 1} failed:`, error);
            if (i < retries - 1) await new Promise(res => setTimeout(res, delay));
        }
    }
    return { emergenciaph: 0, incendio: 0, acidente: 0, outros: 0 };
};

const fetchRequestsByDate = async (date_from, date_to, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(`https://preventech-proxy-service.onrender.com/api/statistics/getRequestsByDate?date_from=${date_from}&date_to=${date_to}`);
            if (!response.ok) throw new Error("Failed to fetch data");
            const data = await response.json();
            return data.summary || { emergenciaph: 0, incendio: 0, acidente: 0, outros: 0 };
        } catch (error) {
            console.error(`Attempt ${i + 1} failed:`, error);
            if (i < retries - 1) await new Promise(res => setTimeout(res, delay));
        }
    }
    return { emergenciaph: 0, incendio: 0, acidente: 0, outros: 0 };
};

const fetchYearlyData = async (year, retries = 3, delay = 1000) => {

    const startDate = `01-01-${year}`;
    const endDate = `31-12-${year}`;

    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(`https://preventech-proxy-service.onrender.com/api/statistics/getRequestsByDate?date_from=${startDate}&date_to=${endDate}`);
            if (!response.ok) throw new Error("Failed to fetch yearly data");
            const data = await response.json();
            return data.summary || { emergenciaph: 0, incendio: 0, acidente: 0, outros: 0 };
        } catch (error) {
            console.error(`Attempt ${i + 1} failed:`, error);
            if (i < retries - 1) await new Promise(res => setTimeout(res, delay));
        }
    }
    return { emergenciaph: 0, incendio: 0, acidente: 0, outros: 0 };
};

const calculateChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
};

function DashboardContent() {
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const today = await fetchDashboardData();
                const yesterdayDate = new Date();
                yesterdayDate.setDate(yesterdayDate.getDate() - 1);
                const day = String(yesterdayDate.getDate()).padStart(2, '0');
                const month = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
                const year = yesterdayDate.getFullYear();
                const formattedYesterday = `${day}-${month}-${year}`;
                const yesterday = await fetchRequestsByDate(formattedYesterday, formattedYesterday);

                const yearlyData = await fetchYearlyData(year);

                const calculateDailyAverage = (yearlyTotal) => {
                    const now = new Date();
                    const startOfYear = new Date(now.getFullYear(), 0, 1); // January 1st of the current year
                    const diff = now - startOfYear; // Difference in milliseconds
                    const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in a day
                    const daysPassed = Math.floor(diff / oneDay) + 1; // +1 to include today
                
                    if (daysPassed === 0) {
                        return 0; // Avoid division by zero if it's the first day of the year
                    }
                
                    return yearlyTotal / daysPassed;
                };

                const formattedData = [
                    {
                        id: 1,
                        title: "Pré-Hospitalar",
                        value: today?.emergenciaph || 0,
                        yesterdayValue: yesterday?.emergenciaph || 0,
                        percentageChange: calculateChange(today?.emergenciaph || 0, yesterday.emergenciaph),
                        category: "emergenciaph",
                        totalYearValue: yearlyData?.emergenciaph || 0,
                        averagePerDay: calculateDailyAverage(yearlyData?.emergenciaph || 0),
                    },
                    {
                        id: 2,
                        title: "Acidentes",
                        value: today?.acidente || 0,
                        yesterdayValue: yesterday?.acidente || 0,
                        percentageChange: calculateChange(today?.acidente || 0, yesterday.acidente),
                        category: "acidente",
                        totalYearValue: yearlyData?.acidente || 0,
                        averagePerDay: calculateDailyAverage(yearlyData?.acidente || 0),
                    },
                    {
                        id: 3,
                        title: "Incêndios",
                        value: today?.incendio || 0,
                        yesterdayValue: yesterday?.incendio || 0,
                        percentageChange: calculateChange(today?.incendio || 0, yesterday.incendio),
                        category: "incendio",
                        totalYearValue: yearlyData?.incendio || 0,
                        averagePerDay: calculateDailyAverage(yearlyData?.incendio || 0),
                    },
                    {
                        id: 4,
                        title: "Outros",
                        value: today?.outros || 0,
                        yesterdayValue: yesterday?.outros || 0,
                        percentageChange: calculateChange(today?.outros || 0, yesterday.outros),
                        category: "outros",
                        totalYearValue: yearlyData?.outros || 0,
                        averagePerDay: calculateDailyAverage(yearlyData?.outros || 0),
                    },
                ];

                setCardData(formattedData);
            } catch (err) {
                setError("Failed to load data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <Typography sx={{ textAlign: "center", mt: 4 }}>Loading...</Typography>;
    }

    if (error) {
        return <Typography sx={{ textAlign: "center", mt: 4, color: "red" }}>{error}</Typography>;
    }

    return (
        <>
            <Grid container spacing={3}>
                {cardData.map((card) => (
                    <Grid item xs={12} sm={6} md={3} key={card.id}>
                        <DashboardCardMui
                            title={card.title}
                            value={card.value}
                            yesterdayValue={card.yesterdayValue}
                            percentageChange={card.percentageChange}
                            category={card.category}
                            totalYearValue={card.totalYearValue}
                            averagePerDay={card.averagePerDay}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default DashboardContent;