import { gapi } from "gapi-script";
import Utils from "./utils";
import pdfFile from "../assets/Verbete-INEM.pdf";

class SendToGoogleDrive {
    static CLIENT_ID =
        "214123389323-3c7npk6e2hasbi2jt3pnrg1jqvjtm92m.apps.googleusercontent.com"; // Replace with your OAuth Client ID
    static API_KEY = "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"; // Replace with your Google Cloud API Key
    static SCOPES = "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send"
 // Scope for Drive file access

    /**
     * Initialize Google API client
     */
    static initializeGapi = () => {
        return new Promise((resolve, reject) => {
            if (!gapi) {
                reject(new Error("Google API script is not loaded."));
                return;
            }

            gapi.load("client:auth2", async () => {
                try {
                    await gapi.client.init({
                        apiKey: SendToGoogleDrive.API_KEY,
                        clientId: SendToGoogleDrive.CLIENT_ID,
                        discoveryDocs: [
                            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
                            "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
                        ],
                        scope: SendToGoogleDrive.SCOPES,
                    });
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        });
    };

    /**
     * Authenticate user with Google
     */
    static authenticateUser = async () => {
    try {
        if (!gapi.client) {
            throw new Error("Google API client is not initialized.");
        }

        const authInstance = gapi.auth2?.getAuthInstance();

        if (!authInstance) {
            throw new Error("Auth instance is undefined. Ensure gapi is initialized.");
        }

        if (authInstance.isSignedIn.get()) {
            return true; // Already signed in
        }

        // Build Google OAuth URL
        const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${SendToGoogleDrive.CLIENT_ID}&response_type=token&scope=${encodeURIComponent(SendToGoogleDrive.SCOPES)}&redirect_uri=${encodeURIComponent(window.location.origin)}`;

        // Detect if PWA is running on iOS
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isInStandaloneMode = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;

        if (isIOS && isInStandaloneMode) {
            // On iOS PWA, replace the current page instead of opening a new tab
            window.location.href = authUrl;
        } else {
            // Open Google OAuth in a new tab for other platforms
            window.open(authUrl, "_blank");
        }

        return false;
    } catch (error) {
        console.error("Authentication error:", error);
        throw error;
    }
};

    /**
     * Upload a file to Google Drive
     * @param {Blob} pdfBlob - The PDF file blob to upload
     * @param {string} fileName - Name of the file to save
     * @param {object} formData - Form data for the PDF
     * @param {string} num_ocorrencia - Folder name (incident number)
     * @param {function} setIsUploading - Function to toggle uploading state
     * @param {function} setUploadProgress - Function to set upload progress
     * @param {object} item - Incident item
     */
    static sendToDrive = async (
        pdfBlob,
        fileName,
        formData,
        num_ocorrencia,
        setIsUploading,
        setUploadProgress,
        item
    ) => {
        try {
            if (
                formData.hora_vitima !== "" &&
                formData.hora_vitima !== null &&
                formData.hora_caminho_hospital !== "" &&
                formData.hora_caminho_hospital !== null &&
                formData.hora_chegada_unidade_hospitalar !== "" &&
                formData.hora_chegada_unidade_hospitalar !== null
            ) {
                // Convert form data to uppercase
                const upperCaseFormData = Object.fromEntries(
                    Object.entries(formData).map(([key, value]) => [
                        key,
                        typeof value === "string" ? value.toUpperCase() : value,
                    ])
                );

                const templateUrl = pdfFile; // Path to your PDF template
                let file = new Blob([pdfBlob], { type: "application/pdf" });

                // Fill the PDF template with uppercase formData and get the filled PDF blob
                file = await Utils.fillPdfTemplate(templateUrl, upperCaseFormData);
                console.log("upperCaseFormData before submission:", upperCaseFormData); // Log formData to check its state

                gapi.auth2
                    .getAuthInstance()
                    .signIn()
                    .then(async () => {
                        const metadata = {
                            name: fileName,
                            mimeType: "application/pdf",
                        };

                        // Step 1: Find or create the 'Ocorrencias' folder in Google Drive shared from centralVPA
                        const ocorrenciasFolderId = await Utils.createOrFindFolder(
                            "1yE6cG3Kwakq1V0ZcI8liMfqF4tLc2E4h"
                        );

                        // Step 2: Inside 'Ocorrencias', find or create the 'num_ocorrencia' folder
                        const ocorrenciaFolderId = await Utils.createOrFindFolder(
                            num_ocorrencia,
                            "1yE6cG3Kwakq1V0ZcI8liMfqF4tLc2E4h"
                        );

                        if (!ocorrenciaFolderId) {
                            alert("Erro ao criar a estrutura de pastas no Google Drive");
                            return;
                        }

                        console.log(ocorrenciaFolderId);

                        if (!ocorrenciaFolderId) {
                            alert("Error creating the folder structure in Google Drive.");
                            return;
                        }

                        const form = new FormData();
                        metadata.parents = [ocorrenciaFolderId];
                        form.append(
                            "metadata",
                            new Blob([JSON.stringify(metadata)], { type: "application/json" })
                        );
                        form.append("file", new Blob([file], { type: "application/pdf" }));

                        const xhr = new XMLHttpRequest();
                        setIsUploading(true);
                        xhr.open(
                            "POST",
                            "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
                            true
                        );
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${gapi.auth.getToken().access_token}`
                        );

                        xhr.upload.onprogress = (event) => {
                            if (event.lengthComputable) {
                                const percentComplete = Math.round(
                                    (event.loaded / event.total) * 100
                                );
                                setUploadProgress(percentComplete);
                            }
                        };

                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                alert("Ficheiro enviado com sucesso para o Google Drive!");
                                setIsUploading(false); // Stop spinner
                                setUploadProgress(0); // Reset progress
                            } else {
                                console.error(
                                    "Erro no upload do ficheiro para o Google Drive: ",
                                    xhr.responseText
                                );
                                setIsUploading(false); // Stop spinner
                            }
                        };

                        xhr.onerror = () => {
                            console.error("Erro no upload do ficheiro para o Google Drive.");
                            setIsUploading(false); // Stop spinner
                        };

                        xhr.send(form); // Send the form data
                    });
            } else {
                alert(
                    "Dados não preenchidos (Hora Chegada à Vitima, Caminho U. Saúde, Chegada U. Saúde ). Por favor, preencha antes de finalizar."
                );
            }
        } catch (error) {
            alert(`Erro: ${error.message}`);
        }
    };


     /**
     * Send an email with a PDF attachment via Gmail API
     */
     static sendToGmail = async (
        pdfBlob,
        fileName,
        formData,
        recipientEmail,
        setIsUploading,
        setUploadProgress
    ) => {
        try {
            if (
                formData.hora_vitima &&
                formData.hora_caminho_hospital &&
                formData.hora_chegada_unidade_hospitalar
            ) {
                // Convert form data to uppercase
                const upperCaseFormData = Object.fromEntries(
                    Object.entries(formData).map(([key, value]) => [
                        key,
                        typeof value === "string" ? value.toUpperCase() : value,
                    ])
                );

                const templateUrl = pdfFile;
                let file = new Blob([pdfBlob], { type: "application/pdf" });

                // Fill the PDF template with uppercase formData
                file = await Utils.fillPdfTemplate(templateUrl, upperCaseFormData);
                console.log("UpperCase FormData before sending email:", upperCaseFormData);

                // Convert the PDF Blob to Base64
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = async () => {
                    const base64File = reader.result.split(",")[1];

                    // Construct MIME email format
                    const email = [
                        `To: ${recipientEmail}`,
                        `Subject: Documento Gerado - ${fileName}`,
                        "MIME-Version: 1.0",
                        "Content-Type: multipart/mixed; boundary=boundary_string",
                        "",
                        "--boundary_string",
                        "Content-Type: text/plain; charset=UTF-8",
                        "",
                        "Olá, segue em anexo o documento gerado.",
                        "",
                        "--boundary_string",
                        `Content-Type: application/pdf; name="${fileName}"`,
                        "Content-Transfer-Encoding: base64",
                        `Content-Disposition: attachment; filename="${fileName}"`,
                        "",
                        base64File,
                        "--boundary_string--"
                    ].join("\r\n");

                    // Correctly encode email using Base64-URL encoding
                    const encodedEmail = btoa(unescape(encodeURIComponent(email)))
                        .replace(/\+/g, "-")
                        .replace(/\//g, "_")
                        .replace(/=+$/, "");

                    setIsUploading(true);

                    // Ensure Google API is initialized and user is authenticated
                    if (!gapi.auth2 || !gapi.auth2.getAuthInstance()) {
                        alert("Google API not initialized. Please refresh and try again.");
                        setIsUploading(false);
                        return;
                    }

                    // Authenticate and send email
                    gapi.auth2.getAuthInstance().signIn().then(() => {
                        gapi.client.gmail.users.messages.send({
                            userId: "me",
                            resource: { raw: encodedEmail },
                        }).then(() => {
                            alert("Email enviado com sucesso via Gmail API!");
                            setIsUploading(false);
                        }).catch((error) => {
                            console.error("Erro ao enviar email: ", error);
                            alert("Erro ao enviar email.");
                            setIsUploading(false);
                        });
                    }).catch((error) => {
                        console.error("Erro na autenticação do usuário:", error);
                        alert("Erro na autenticação. Por favor, faça login na sua conta Google.");
                        setIsUploading(false);
                    });
                };
            } else {
                alert(
                    "Dados não preenchidos (Hora Chegada à Vítima, Caminho U. Saúde, Chegada U. Saúde). Por favor, preencha antes de finalizar."
                );
            }
        } catch (error) {
            console.error("Erro ao enviar email:", error);
            alert(`Erro: ${error.message}`);
            setIsUploading(false);
        }
    };
}

export default SendToGoogleDrive;
